import { graphql } from 'gatsby';
import Privacy from './docs/privacy';

export default function NotFoundPage() {
  return <Privacy />;
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
